import React from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line
export default () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Dziękujemy za wiadomość</h1>
          </div>
        </div>
      </section>
    </Helmet>
  </>
);
